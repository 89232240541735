import {useMotionValue, motion, useSpring, useTransform} from "framer-motion";
import React, {useRef} from "react";
import FiArrowRight from "./data/arrow-right.svg";
import img1 from "./data/cki.webp"
import img2 from "./data/four_moons.webp"
import img3 from "./data/house_of_sansa.webp"
import img4 from "./data/lable_mahima_sethiya.webp"



let data = [
    {
        "title": 'Concept Kitchen India',
        "link": "./",
        "id": 1,
        "img": img1
    }, {
        "title": '',
        "link": './',
        "id": 2,
        "img": img2
    }, {
        "title": 'House of Sansa',
        "link": "./",
        "id": 3,
        "img": img3
    },
    {
        "title": 'Label Mahima Sethiya',
        "link": "./",
        "id": 4,
        "img": img4
    }]
export const HoverLinks = () => {
    return (
        <section className="bg-neutral-950 pb-10">
            <div className="hoverlinkclass">
                <div className="max-w-[100%] pt-4">
                    <div className="link">
                    <Link
                        heading="Concept Kitchen India"
                        // subheading="Learn what we do here"
                        imgSrc={img1}
                        href="./"

                    /> </div> <div className="link"><Link
                    heading="four Moons"
                    // subheading="Learn what we do here"
                    imgSrc={img2}
                    href="./"
                /> </div> <div className="link"><Link
                    heading="House of Sansa"
                    // subheading="Learn what we do here"
                    imgSrc={img3}
                    href="./"
                /></div> <div className="link"> <Link
                    heading="Label Mahima Sethiya"
                    // subheading="Learn what we do here"
                    imgSrc={img4}
                    href="./"
                /> </div>
                    {/*{data.map((info) => {*/}
                    {/*    return (*/}
                    {/*        */}
                    {/*            <Link*/}
                    {/*                heading={info.title}*/}
                    {/*                // subheading="Learn what we do here"*/}
                    {/*                imgSrc={info.img}*/}
                    {/*                href="#"*/}
                    {/*            />*/}
                    {/*})}*/}
                </div>

            </div>
        </section>
    );

};

const Link = ({
                  heading, imgSrc, subheading, href
              }) => {
    const ref = useRef(null);

    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const mouseXSpring = useSpring(x);
    const mouseYSpring = useSpring(y);

    const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
    const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

    const handleMouseMove = (e) => {
        const rect = ref.current.getBoundingClientRect();

        const width = rect.width;
        const height = rect.height;

        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        const xPct = mouseX / width - 0.5;
        const yPct = mouseY / height - 0.5;

        x.set(xPct);
        y.set(yPct);
    };

    return (
        <motion.a
            href={href}
            ref={ref}
            onMouseMove={handleMouseMove}
            initial="initial"
            whileHover="whileHover"
            className="group relative flex items-center justify-between border-b-2 border-neutral-700 py-4 transition-colors duration-500 hover:border-neutral-50"
        >
            <div>
                {/*<motion.span*/}
                {/*  variants={{*/}
                {/*    initial: { x: 0 },*/}
                {/*    whileHover: { x: -16 },*/}
                {/*  }}*/}
                {/*  transition={{*/}
                {/*    type: "spring",*/}
                {/*    staggerChildren: 0.075,*/}
                {/*    delayChildren: 0.25,*/}
                {/*  }}*/}
                {/*  className="relative z-10 block text-4xl font-bold text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50 md:text-6xl"*/}
                {/*>*/}
                {/*  {heading.split(/(\s+)/).map((l, i) => (*/}
                {/*    <motion.span*/}
                {/*      variants={{*/}
                {/*        initial: { x: 0 },*/}
                {/*        whileHover: { x: 16 },*/}
                {/*      }}*/}
                {/*      transition={{ type: "spring" }}*/}
                {/*      className="inline-block"*/}
                {/*      key={i}*/}
                {/*    >*/}
                {/*      {l}*/}
                {/*    </motion.span>*/}
                {/*  ))}*/}
                {/*</motion.span>*/}
                <span
                    className="text-[#DC3545] text-[20px] not-italic font-normal leading-[normal] uppercase font-newYork text-nowrap group-hover:text-[#E82D11] sm:text-[38.328px]">
            {/*"relative z-10 block text-4xl font-bold text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50 md:text-6xl accent-#a90d06"*/}
                    {heading}
            </span>

                <span
                    className="relative z-10 mt-2 block text-base text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50">
          {subheading}
        </span>
            </div>

            <motion.img
                style={{
                    top,
                    left,
                    translateX: "-50%",
                    translateY: "-50%",
                }}
                variants={{
                    initial: {scale: 0, rotate: "-12.5deg"},
                    whileHover: {scale: 1, rotate: "12.5deg"},
                }}
                transition={{type: "tween"}}
                src={imgSrc}
                className="absolute z-50 h-auto w-32 rounded-lg object-cover md:h-auto md:w-96"
                alt={`Image representing a link for ${heading}`}
            />

            <motion.div
                variants={{
                    initial: {
                        x: "25%",
                        opacity: 0,
                    },
                    whileHover: {
                        x: "0%",
                        opacity: 1,
                    },
                }}
                transition={{type: "spring"}}
                className="relative z-10 p-4 hidden sm:flex"
            >
                <a href="./" className="items-center hidden font-PolySans sm:flex">
                    More
                    <img src={FiArrowRight} alt="" className="text-5xl text-neutral-50"/></a>
            </motion.div>
        </motion.a>
    );
};
