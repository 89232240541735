import React from 'react';
import logo from "./data/Dog's Tail Exp 1.svg"
import {useRef} from 'react'
const Navbar = () => {
    const projects = useRef(null)
    const services = useRef(null)
    const about = useRef(null)
    const contact = useRef(null)

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
        })
    }
    return (

        <nav className="navbar">
            <img src={logo} alt="Dog's tail logo"/>
            {/*<p>Menu</p>*/}
            <div className="items">
                <a onClick={() => scrollToSection(projects)}> Projects </a>
                <a onClick={() => scrollToSection(services)}> Services </a>
                <a onClick={() => scrollToSection(about)}> About Us </a>
                <a onClick={() => scrollToSection(contact)}> Contact </a>
            </div>
        </nav>);
};

export default Navbar;
