import React from 'react';
import arrow from './data/arrow-right.svg'
import useAOS from "./useAOS";
import contact from "./Navbar"
const Contact = () => {
    useAOS()
    return (
        <div className="Contact" data-aos="fade-in" data-aos-anchor-placement="bottom-bottom" id="contact">
            <h3>Contact us</h3>
            <p>Let's transform your vision into captivating creations</p>
            <a href="mailto:dogstailexp@gmail.com"> dogstailexp@gmail.com
                <img src={arrow} alt="" className="arrow"/>
            </a>
            <a href="tel:+9170241-47300">
                +91 70241-47300
                <img src={arrow} alt="" className="arrow"/>
            </a>
        </div>
    );
};

export default Contact;
