import React from 'react';
import character from './data/footerMobile.mp4'
import useAOS from "./useAOS";
import characterbig from './data/FooterDesktop.mp4'

const About = () => {
    let x
    if (window.innerWidth >  767) {
        x = <video src={characterbig} alt="video" loop muted playsInline autoPlay></video>
    }
    else{
        x = <video src={character} alt="video" loop muted playsInline autoPlay></video>
    }
    useAOS()
    return (
        <div className="About" data-aos="fade-up"  id="about">
            <h3 data-aos-anchor-placement="center-bottom"> About us </h3>
            <p data-aos-anchor-placement="center-bottom">In the realm where imagination meets strategy,
                I orchestrate stories that captivate, designs that transcend,
                and experiences that leave an indelible mark. </p>
            {x}

        </div>
    );
};

export default About;
