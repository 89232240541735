import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from './data/loud-speaker.png'
import img2 from './data/paint-brush-hand-drawn-tool.png'
import img3 from './data/photo-camera-hand-drawn-tool.png'
import img4 from './data/shop.png'
import img5 from './data/wedding-ring.png'

export default function ServicesNew() {
    // Responsive settings for the carousel
    const responsiveSettings = {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
    };

    return (
        <div className='container'>
            {/* // className "owl-theme" is optional */}
            <OwlCarousel
                className='owl-theme'
                loop
                margin={30}
                dots=''
                responsive={responsiveSettings} // Add responsive settings here
            >
                <div class='item'>
                    <div className='flex flex-col'>
                        <div className='flex items-center justify-center w-full py-[110px] rounded-xl border border-[#A90D06]'>
                            <img src={img1} alt="" className="serviceIcons" />
                        </div>
                        <div className='my-5'>
                            <h3 className='newyork text-3xl text-[#A90D06] uppercase'>campaign design</h3>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div className='flex flex-col'>
                        <div className='flex items-center justify-center w-full py-[110px] rounded-xl border border-[#A90D06]'>
                            <img src={img2} alt="" className="serviceIcons" />
                        </div>
                        <div className='my-5'>
                            <h3 className='newyork text-3xl text-[#A90D06] uppercase'>art direction</h3>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div className='flex flex-col'>
                        <div className='flex items-center justify-center w-full py-[110px] rounded-xl border border-[#A90D06]'>
                            <img src={img3} alt="" className="serviceIcons" />
                        </div>
                        <div className='my-5'>
                            <h3 className='newyork text-3xl text-[#A90D06] uppercase'>fashion photography</h3>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div className='flex flex-col'>
                        <div className='flex items-center justify-center w-full py-[110px] rounded-xl border border-[#A90D06]'>
                            <img src={img4} alt="" className="serviceIcons" />
                        </div>
                        <div className='my-5'>
                            <h3 className='newyork text-3xl text-[#A90D06] uppercase'>window display</h3>
                        </div>
                    </div>
                </div>
                <div class='item'>
                    <div className='flex flex-col'>
                        <div className='flex items-center justify-center w-full py-[110px] rounded-xl border border-[#A90D06]'>
                            <img src={img5} alt="" className="serviceIcons" />
                        </div>
                        <div className='my-5'>
                            <h3 className='newyork text-3xl text-[#A90D06] uppercase'>fashion photography</h3>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    )
}
